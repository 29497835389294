<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="title">Set Reward Tips</div>

          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close close-icon" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3">
      <template v-for="(reward, index) in rewardSet" :key="index">
        <div class="reward-section p-1" :class="{ 'mb-2 ': index < rewardSet.length - 1 }">
          <div class="d-flex align-items-center">
            <div class="number mx-2">{{ index + 1 }}</div>
            <div class="set-section d-flex align-items-center w-100 p-2 mx-2">
              <inline-svg class="svg" :src="reward.svg" />
              <div class="sub-title mx-3">{{ reward.title }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  virtualItem: {
    type: Object,
    default: {},
  },
  selectedUser: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');
const rewardSet = ref([
  { title: 'Unlock set pieces!', svg: '/unlock-set.svg' },
  { title: 'Claim the Set Reward!', svg: '/claim-set.svg' },
]);

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.set-section
  background: white
  border-bottom-right-radius: 20px
  border-top-right-radius: 20px
.number
   font: bolder 34px "Roboto", sans-serif
   -webkit-text-fill-color: #431C89
   @media(max-width: 350px)
    font: bolder 28px "Roboto", sans-serif
.reward-section
   background: #fff5d6
   border-radius: 20px
.sub-title
   color: #431C89
   font-size: 24px
   font-weight: bold
   @media(max-width:500px)
    font-size: 20px
   @media(max-width:350px)
    font-size: 16px
.svg
    width: 100%
    height:100%
.toolbar
    --background: #9874DB !important
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #9874DB)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 530px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-icon
  color: white
.title
  color: white
  font-size: 20px
</style>
