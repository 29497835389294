<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="d-flex align-items-center title">Set Reward Claimed</div>

          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3">
      <div class="d-flex align-items-center justify-content-center mt-2 mb-2">
        <img class="cheer-img" src="/cheer.png" />
        <div class="d-flex align-items-center flex-column e">
          <div class="cheer-title">Congratulations!!</div>
          <div class="cheer-sub-title">You got the Set Reward!!</div>
        </div>
      </div>
      <div class="items-border-section p-2">
        <VirtualItemsDisplay :item="item" :claimed="true" />
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button color="transparent" class="close-btn" @click="dismissModal"> Close </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import { checkmarkOutline, personCircleOutline, giftOutline } from 'ionicons/icons';
import { purchaseVirtualItem } from '../actions/virtualItems';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  item: {
    type: Object,
    default: {},
  },
});
const item = toRef(props, 'item');
const emits = defineEmits(['close', 'success']);
const isOpen = toRef(props, 'isOpen');

watch(isOpen, async () => {
  if (isOpen.value) {
    const resp = await purchaseVirtualItem(item.value.id);

    if (get(resp, 'purchase_status')) {
      emits('success', item.value.id);
    }
  }
});
const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.cheer-sub-title
    font: bolder 20px "Roboto Slab", sans-serif
    -webkit-text-fill-color: #431C89
.dark .cheer-sub-title
    -webkit-text-fill-color: #fff !important
.cheer-title
  font: bolder 26px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #431C89
.dark .cheer-title
    -webkit-text-fill-color: #fff !important
.cheer-img
  width: 150px
  height: 150px
  object-fit: cover
.items-border-section
  border: 2px solid #fff
  border-radius: 16px
.dark .toolbar
  --background: #17074c !important
.close-btn
  border-radius: 18px
  text-transform: none
  --background: #7050B7
  background:  #7050B7
  color: white
  height: 30px
  font-weight: 600
.modal-inner-content
  background: var(--ion-background-color, linear-gradient(180deg, rgba(210, 228, 255, 0) 0%, #D2E4FF 100%), #D7B9FF)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #9A64FD
  font-size: 20px
.dark .title
  color: #fff !important
</style>
