<template>
  <div
    class="ring-card position-relative py-3 px-2 d-flex justify-content-center flex-column"
    :class="ringCardClass"
    :style="ringCardStyle"
    @click="openPurchaseModal"
  >
    <div class="position-absolute checkmark-circle" :class="checkmarkClass">
      <i class="ti-check check-icon" />
    </div>
    <div class="reward-claimed" v-if="isReward">Set Reward</div>
    <vue-countdown
      v-if="iteminStoretimer"
      v-slot="{ days, hours, minutes, seconds }"
      :time="formatFromNowInMilliseconds(iteminStoretimer)"
      @end="fetchRingCategories"
      class="d-flex align-items-center mb-2 mt-2 w-100"
    >
      <div v-if="whiteCardColor" class="timer w-100" :style="{ color: timerClass }">
        <i class="ti-time mr-1 time-icon" />
        <span class="mr-1" v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
        ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
      </div>
      <div class="info-icon-badge ml-1" @click.prevent.stop="openRewardInfoModal" v-if="hasUnlockedProperty">
        <i class="ti-info-alt info-icon" />
      </div>
    </vue-countdown>

    <ion-icon v-if="item.icon" class="mr-1 prf-ring-icon mx-auto" style="color: red" :icon="banOutline" />
    <div
      class="position-relative d-flex align-items-center justify-content-center mx-auto mb-3"
      :style="ringMarginStyle"
      v-else
    >
      <img id="charimg" :src="profilePicture" class="user-prf-pic" />
      <img :src="item.image" class="prf-ring-img" />
    </div>
    <div :class="{ 'set-direction': !whiteCardColor }" class="mt-auto">
      <div
        class="align-items-center d-flex text-center justify-content-center position-relative"
        :class="{ 'title-margin': isEmpty(get(item, 'virtual_item_type')) && whiteCardColor }"
      >
        <div class="truncate" :style="!isUnlocked && hasUnlockedProperty ? 'color: #214163 !important' : ''">
          {{ item.title }}
        </div>
      </div>
      <div class="d-flex text-center justify-content-center">
        <div v-if="get(item, 'virtual_item_type')" :style="{ color: vItemColor }" class="types truncate">
          {{ itemTypeName }}
        </div>
      </div>
      <div class="mt-auto align-items-center d-flex position-relative" v-if="isReward">
        <ion-button
          class="d-flex justify-content-center reward-btn align-items-center w-100"
          :class="{
            'claimed-btn': isReward && isUnlocked && unlockedCounts > totalCounts,
            'claim-btn': isReward && !isUnlocked && unlockedCounts >= totalCounts,
          }"
          @click="claimAction"
        >
          {{ subActionText }}</ion-button
        >
      </div>
      <div class="mt-auto align-items-center d-flex position-relative" v-else>
        <div
          class="d-flex justify-content-center item-btn align-items-center w-100"
          v-if="amountSection"
          :class="item.is_bought ? 'item-btn-size' : 'clickable-item-hov'"
          :style="itemButtonStyle"
          @click.prevent.stop="openBuyPrfRingModal"
        >
          <i v-if="handleItemBought" class="ti-check mr-1" style="color: #6adf32" />
          <i v-if="isUnlocked" class="ti-unlock mr-1 unlock-icon" />
          <div
            class="token mr-1"
            v-if="imageFromItemsCurrency && !item.is_bought && !isUnlocked"
            :style="prfaddOns ? 'height:21px !important' : ''"
          >
            <CurrencyImage :type="get(item, 'currency_type')" />
            <inline-svg :src="imageFromItemsCurrency" class="token-svg" />
          </div>
          <span class="amount" :style="amountStyle">
            {{ isUnlocked ? 'Unlocked' : handleItemBought ? 'Purchased' : item.amount.toLocaleString() }}
          </span>
        </div>
        <ion-button
          color="transparent"
          style="width: 21%"
          @click.prevent.stop="openChangeUserSelectionModal"
          v-if="whiteCardColor"
        >
          <div
            class="d-flex align-items-center p-1 clickable-item-hov"
            style="border-radius: 20px; border: 1px solid #00b4c5"
          >
            <ion-icon class="gift-icon" :icon="giftOutline" />
          </div>
        </ion-button>
      </div>
    </div>

    <RingBearerPurchaseModal
      :is-open="isOpenRingModal"
      @close="isOpenRingModal = false"
      @purchase="closeRingModal"
      :item="item"
      @success="fetchCustomSet"
    />

    <RingBearerPurchaseModal
      :is-open="isOpenRingConfirmationModal"
      :item="item"
      @updated="closePurchaseRingModal"
      :isPurchased="isPurchased"
    />
    <PurchaseVirtualItemsModal
      :is-open="isOpenRingPurchaseModal"
      :item="item"
      @close="isOpenRingPurchaseModal = false"
      @gift="openUserSelectionModal"
      @purchase="openConfirmationPurchaseModal"
    />
    <PrfRingUserGiftSelectionModal
      :is-open="isOpenGiftUserSelectionModal"
      :item="item"
      @close="isOpenGiftUserSelectionModal = false"
      @send="openGiftModal"
    />
    <GiftVirtualItemsModal
      :is-open="isOpenGiftModal"
      @close="isOpenGiftModal = false"
      :virtualItem="item"
      :selected-user="selectedUser"
      @change="openChangeUserSelectionModal"
      @gift="closeAndOpenGiftPurchaseModal"
      @updated="emits('get')"
    />
    <VirtualItemsGiftPurchaseModal
      :is-open="isOpenGiftPurchaseModal"
      :virtualItem="item"
      :selected-user="selectedUser"
      @close="isOpenGiftPurchaseModal = false"
    />

    <SetRewardClaimedModal :is-open="isOpenClaimedSetRewardModal" :item="item" @close="closeClaimModal" />
    <SetRewardInfoModal :is-open="isOpenRewardInfoModal" @close="isOpenRewardInfoModal = false" />
  </div>
</template>

<script lang="ts" setup>
import { banOutline, giftOutline } from 'ionicons/icons';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
import RingBearerPurchaseModal from '@/shared/modals/ItemsPurchaseModal.vue';
import PrfRingUserGiftSelectionModal from '~/shared/modals/VirtualItemsUserGiftSelectionModal.vue';
import NotEnoughCoinsPopover from '@/shared/pages/store/popovers/NotEnoughCoinsPopover.vue';
import GiftVirtualItemsModal from '~/shared/modals/GiftVirtualItemsModal.vue';
import VirtualItemsGiftPurchaseModal from '~/shared/modals/VirtualItemsGiftPurchaseModal.vue';
import SetRewardClaimedModal from '~/shared/modals/SetRewardClaimedModal.vue';
import PurchaseVirtualItemsModal from '~/shared/modals/PurchaseVirtualItemsModal.vue';
import SetRewardInfoModal from '~/shared/modals/SetRewardInfoModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { popovers } from '@/shared/native/popovers';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';
const props = defineProps({
  whiteCardColor: { type: Boolean, default: false },
  amountSection: { type: Boolean, default: false },
  item: { type: Object, default: () => ({}) },
  selectedRing: { type: Boolean, default: false },
  selectedUserChar: { type: Object, default: {} },
  prfaddOns: { type: Boolean, default: false },
  totalCounts: { type: Number, default: 0 },
  unlockedCounts: { type: Number, default: 0 },
});

const { width } = useWindowSize();
const emits = defineEmits(['updated', 'fetch', 'success', 'get']);
const whiteCardColor = toRef(props, 'whiteCardColor');
const item = toRef(props, 'item') as any;
const isOpenRewardInfoModal = ref(false);
const isOpenGiftModal = ref(false);
const isOpenGiftUserSelectionModal = ref(false);
const isPurchased = ref(false);
const selectedUser = ref({});
const unlockedCounts = toRef(props, 'unlockedCounts');
const totalCounts = toRef(props, 'totalCounts');
const isOpenClaimedSetRewardModal = ref(false);
const isOpenRingPurchaseModal = ref(false);
const isOpenRingConfirmationModal = ref(false);
const isOpenGiftPurchaseModal = ref(false);
const isOpenRingModal = ref(false);
const { usersCurrency, user } = authStore();
const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(item.value);
const selectedUserChar = toRef(props, 'selectedUserChar');
const { dark } = mainStore();

const isReward = computed(() => {
  return get(item.value, 'is_reward');
});
const isUnlocked = computed(() => {
  return get(item.value, 'is_unlocked');
});

const ringCardClass = computed(() => ({
  'feature-clr': whiteCardColor.value,
  'border-clr': !props.selectedRing && !whiteCardColor.value,
  active: props.selectedRing && !whiteCardColor.value,
  'clickable-item-hov': !item.value.is_bought,
  'disabled-card': !isUnlocked.value && unlockedCounts.value !== totalCounts.value && hasUnlockedProperty.value,
  'claim-card': isReward.value && unlockedCounts.value >= totalCounts.value,
}));

const openRewardInfoModal = () => {
  isOpenRewardInfoModal.value = true;
};
const iteminStoretimer = computed(() => {
  return get(item.value, 'in_store_till');
});

const ringCardStyle = computed(() => {
  if (whiteCardColor.value) {
    if (width.value > 390) return { 'min-height': '260px' };
    else if (width.value <= 390 && width.value >= 320) return { 'min-height': '220px' };
    else if (width.value <= 320) return { 'min-height': '190px' };
  } else return { 'min-height': width.value >= 400 ? '198px' : '160px' };
});

const hasUnlockedProperty = computed(() => {
  return Object.prototype.hasOwnProperty.call(item.value, 'is_unlocked');
});

const handleItemBought = computed(() => {
  return item.value.is_bought;
});

const subActionText = computed(() => {
  if (isReward.value && isUnlocked.value) return 'Claimed';
  else if (unlockedCounts.value >= totalCounts.value) return 'Claim';
  else return `${unlockedCounts.value}/${totalCounts.value}`;
});
const claimAction = () => {
  if (get(item.value, 'is_reward') && get(item.value, 'is_unlocked')) return;
  else if (get(item.value, 'is_reward') && unlockedCounts.value >= totalCounts.value) {
    isOpenClaimedSetRewardModal.value = true;
  }
};
const ringMarginStyle = computed(() => {
  const isSmallScreen = width.value <= 320;
  const isLocked = !isUnlocked.value && hasUnlockedProperty.value;

  if (!iteminStoretimer.value && whiteCardColor.value) {
    return {
      opacity: isLocked ? '0.2' : '',
      'margin-top': isSmallScreen ? '36px' : '45px',
    };
  }

  return {};
});
const checkmarkClass = computed(() =>
  props.selectedRing && !whiteCardColor.value ? 'checkmark-active' : 'checkmark-hidden'
);
const openConfirmationPurchaseModal = () => {
  isOpenRingPurchaseModal.value = false;
  isOpenRingModal.value = true;
};
const closeClaimModal = () => {
  isOpenClaimedSetRewardModal.value = false;
  emits('get');
};

const openUserSelectionModal = () => {
  isOpenRingPurchaseModal.value = false;
  isOpenGiftUserSelectionModal.value = true;
};

const openGiftModal = (val: any) => {
  selectedUser.value = val;
  isOpenGiftUserSelectionModal.value = false;
  isOpenGiftModal.value = true;
};

const closeAndOpenGiftPurchaseModal = () => {
  isOpenGiftModal.value = false;
  isOpenGiftPurchaseModal.value = true;
};

const timerClass = computed(() => {
  if (!iteminStoretimer.value) return;
  const remainingHours = formatFromNowInMilliseconds(get(item.value, 'in_store_till')) / (1000 * 60 * 60);
  if (remainingHours < 2) return '#ffab10';
  else if ((dark.value && remainingHours >= 2 && !hasUnlockedProperty.value) || (dark.value && isUnlocked.value)) {
    return '#fff';
  } else if (remainingHours < 24) return '#451f8a';
  else return '#7050B7';
});
const imageWdhAndHgt = computed(() => {
  if (width.value >= 650) return 100;
  else if (width.value >= 320) return 60;
  else if (width.value >= 290) return 47;
  return 34;
});

const itemTypeName = computed(() => {
  return get(item.value, 'virtual_item_type.title');
});
const vItemColor = computed(() => {
  return get(item.value, 'virtual_item_type.color_code', '#328FD2');
});
const fetchRingCategories = () => {
  emits('fetch');
};

const currencyAmount = computed(() => {
  return item.value.currency_type === 'gem' ? usersCurrency.value.gems : usersCurrency.value.coins;
});

const openPopover = (ev: MouseEvent) => {
  popovers.open(ev, NotEnoughCoinsPopover, { currencyType: item.value.currency_type });
};

const openChangeUserSelectionModal = (ev: MouseEvent) => {
  if (currencyAmount.value >= item.value.amount) {
    isOpenGiftUserSelectionModal.value = true;
  } else {
    openPopover(ev);
  }
};
const openBuyPrfRingModal = (ev: MouseEvent) => {
  if (item.value.is_bought || isReward.value || isUnlocked.value) return;
  else if (currencyAmount.value >= item.value.amount) {
    isOpenRingModal.value = true;
  } else {
    openPopover(ev);
  }
};
const openPurchaseModal = (ev: MouseEvent) => {
  if (whiteCardColor.value) {
    if (item.value.is_bought || isReward.value || isUnlocked.value) return;
    else if (currencyAmount.value >= item.value.amount) {
      isOpenRingPurchaseModal.value = true;
    } else {
      openPopover(ev);
    }
  }
};
const profilePicture = computed(() => {
  if (isEmpty(selectedUserChar.value)) return user.value.profile_picture_url;
  else if (!isEmpty(get(selectedUserChar.value, 'slug')) || get(selectedUserChar.value, 'type') === 'draft') {
    return get(selectedUserChar.value, 'info.cropProfilePicture', '/empty.png');
  } else return get(selectedUserChar.value, 'profile_picture_url');
});
const ringMargin = computed(() => {
  const ring = Math.ceil((imageWdhAndHgt.value * (25 / 100)) / 2);
  return ring;
});

const ringBorderWidth = computed(() => {
  let ringImgSize: any = Math.ceil(imageWdhAndHgt.value + imageWdhAndHgt.value * (25 / 100));
  return ringImgSize;
});

const closeRingModal = () => {
  isOpenRingModal.value = false;
  setTimeout(() => {
    isPurchased.value = true;
    isOpenRingConfirmationModal.value = true;
  }, 100);
};

const closePurchaseRingModal = () => {
  isOpenRingConfirmationModal.value = false;
  setTimeout(() => emits('updated'), 150);
};
const fetchCustomSet = (id: any) => {
  emits('success', id);
};

const itemButtonStyle = computed(() => {
  if (isUnlocked.value) return 'border: 2px solid #6adf32';
  else if (!isUnlocked.value && hasUnlockedProperty.value) return 'background: white';
  else return '';
});

const amountStyle = computed(() => {
  if (!isUnlocked.value && hasUnlockedProperty.value) return 'color: #214163 !important';
  else if (handleItemBought.value) return 'font-size:14px;';
  else if (isUnlocked.value) return 'color: #6adf32';
  return '';
});
</script>
<style lang="sass" scoped>
.unlock-icon
  color: #6adf32
  @media(max-width:350px)
    font-size: 10px
.title-margin
  margin-top:24px
.claim-card
  background: linear-gradient(180deg, rgba(210, 228, 255, 0) 0%, #D2E4FF 100%), #D7B9FF !important
  border: 2px solid #977DFF !important
.claim-btn
  width: 100%
  --border-radius: 20px
  height: 37px
  font-weight: bolder
  font-size:19px
  color: white !important
  border: none !important
  --background: linear-gradient(360deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #a83cff !important
.claim-btn::part(native)
  border: none !important
  border: 0px solid
.disabled-card
  background: #EAECF6 !important
.gift-icon
  font-size: 18px !important
  color: #00b4c5
  @media(max-width:350px)
    font-size: 10px !important
.reward-claimed
  font-weight: bold
  width: 85%
  top: -11px
  background: linear-gradient(90deg, rgba(255, 49, 222, 0.7) 0%, rgba(247, 6, 207, 0) 100%), #4155c9
  height: 16px
  position: absolute
  z-index: 10
  border-radius: 20px
  display: flex
  align-items: center
  justify-content: center
  color: white
  font-size: 12px
.info-icon-badge
  background: #000000b5
  height: 23px
  width: 28px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 20px
  @media(max-width:350px)
    height: 15px
    width: 18px
.info-icon
  color: white
  font-size: 14px
  @media(max-width:350px)
    font-size: 10px
.time-icon
  font-size: 14px
  font-weight: bold
  @media(max-width:500px)
    font-size: 0.7rem !important
  @media(max-width:335px)
    font-size: 8px !important
.timer
  font-size: 15px
  font-weight: bold
  border-radius: 20px
  border: 2px solid
  display: flex
  align-items: center
  text-align: center
  justify-content: center
  margin-top: 0.15rem
  color: #7050B7
  @media(max-width:500px)
    font-size: 0.7rem !important
  @media(max-width:336px)
    font-size: 6px !important
.types
  font-weight: bold
  @media(max-width:450px)
    font-size: 13px
.user-prf-pic
  width: v-bind('`${imageWdhAndHgt}px`')
  height: v-bind('`${imageWdhAndHgt}px`')
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  object-fit: cover

.set-direction
  display: flex
  flex-direction: column-reverse
.active
  border: 3px solid #ae38e5 !important
.checkmark-circle
  border: 3px solid var(--ion-color-primary)
  background: white
  width: 25px
  height: 25px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 20px
  right: 5px
  top: 3px

.checkmark-active
  visibility: visible
.checkmark-hidden
  visibility: hidden
.check-icon
  font-size: 12px
  color: var(--ion-color-primary)
.amount
  font-weight: bold
  @media(max-width:560px)
    font-size: 12px
  @media(max-width:380px)
    font-size: 10px !important
  @media(max-width:350px)
    font-size: 8px !important
.dark .amount
  color: white
.prf-ring-icon
  width: 100px
  height: 94px
  object-fit: contain
  min-width: 100%
  max-width: 100%
  color: red
  @media(max-width:391px)
    height: 60px

.prf-ring-img
  position: absolute
  top: v-bind('`-${ringMargin}px`')
  left: v-bind('`-${ringMargin}px`')
  height: v-bind('`${ringBorderWidth}px`')
  width: v-bind('`${ringBorderWidth}px`')
  min-width: v-bind('`${ringBorderWidth}px`')
.token-svg
  width: 15px
  height: 15px
  @media(max-width:350px)
    width: 12px !important
    height: 12px
  @media(max-width:305px)
    width: 10px !important
    height: 10px

.token
  width: 15px
  height: 26px
  @media(max-width:350px)
    width: 12px !important
    height: 25px
  @media(max-width:305px)
    width: 10px !important
    height: 25px
.claimed-btn
  color: #88DC3B !important
.claimed-btn::part(native)
  border: 2px solid #88DC3B !important
.reward-btn
  --background: white
  color: #214163
  height: 32px
  font-weight: bold
  --border-radius: 20px
  @media(max-width:350px)
    height: 26px !important
.reward-btn::part(native)
    border: 2px solid #ae38e5
.item-btn
    --background: transparent
    border: 2px solid #c6c8d6
    color: #214163
    height: 32px
    border-radius: 20px
    @media(max-width:350px)
      height: 22px !important
    @media(max-width:305px)
      height: 20px !important
.item-btn-size
  pointer-events: none
  @media(max-width:380px)
    width:76px
.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 16px

.rings
  margin: 8px
  border-radius: 50px
  width: 67px
  height: auto
  aspect-ratio: 1/1

.ring-section
  margin: 3px
  border-radius: 10px
  position: relative
  width: 145px
  padding-bottom: 10px
  @media(max-width: 500px)
    width: 135px
.border-clr
  border: 2px solid white !important
.feature-clr
  border: 2px solid #f5ebff
  background: white
  aspect-ratio: 1
  box-shadow: 1px 4px 1px 1px rgba(33, 65, 99, 0.19)
.dark .feature-clr
  background: #17074c
.ring-card
  height: 100%
  width: 100%
  border-radius: 17px
  position: relative
</style>
